import { baseApi } from '@alltrails/redux-helpers';
import { AreaSearchResult, AreaType, TrailSearchResult } from '@alltrails/search/types/algoliaResultTypes';
import ExploreServicePayload from '../types/ExploreServicePayload';
import ExploreServiceResponse from '../types/ExploreServiceResponse';

const url = '/api/alltrails/explore/v1/search';

export const exploreServiceApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getAreaTrails: builder.mutation<
      Pick<ExploreServiceResponse<TrailSearchResult>, 'boundingBox' | 'searchResults'>,
      Pick<ExploreServicePayload, 'location'>
    >({
      query: payload => {
        const data: ExploreServicePayload = { ...payload, resultsToInclude: ['boundingBox', 'searchResults'] };
        return { url, data, method: 'post' };
      }
    }),
    getLocationData: builder.mutation<
      Pick<ExploreServiceResponse<TrailSearchResult>, 'boundingBox' | 'searchResults'>,
      Pick<ExploreServicePayload, 'filters' | 'limit' | 'location' | 'page' | 'recordAttributesToRetrieve' | 'sort'>
    >({
      query: payload => {
        const data: ExploreServicePayload = { ...payload, resultsToInclude: ['boundingBox', 'searchResults'] };
        return { url, data, method: 'post' };
      }
    }),
    getParks: builder.mutation<
      Pick<ExploreServiceResponse<AreaSearchResult>, 'searchResults'>,
      Pick<ExploreServicePayload, 'limit' | 'location'> & { areaTypes?: AreaType[] }
    >({
      query: ({ areaTypes, limit, location }) => {
        const data: ExploreServicePayload = {
          filters: {
            collections: ['parks'],
            ...(areaTypes && { areaType: areaTypes })
          },
          ...(limit && { limit }),
          location,
          resultsToInclude: ['searchResults']
        };
        return { url, data, method: 'post' };
      }
    }),
    getTrails: builder.mutation<
      Pick<ExploreServiceResponse<TrailSearchResult>, 'searchResults'>,
      Pick<ExploreServicePayload, 'filters' | 'location' | 'sort'>
    >({
      query: payload => {
        const data: ExploreServicePayload = { ...payload, resultsToInclude: ['searchResults'] };
        return { url, data, method: 'post' };
      }
    })
  })
});

export const { useGetAreaTrailsMutation, useGetLocationDataMutation, useGetParksMutation, useGetTrailsMutation } = exploreServiceApi;
