'use client';

import PlusCta from '../PlusCta';
import useShouldRenderPlusCta from '@/hooks/useShouldRenderPlusCta';

const LocationPageHeader = () => {
  const renderPlusCta = useShouldRenderPlusCta();

  return renderPlusCta ? <PlusCta /> : null;
};

export default LocationPageHeader;
