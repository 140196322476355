'use client';

import logShowMoreClicked from '@alltrails/analytics/events/logShowMoreClicked';
import PageType from '@alltrails/analytics/enums/PageType';
import ShowMoreLocation from '@alltrails/analytics/enums/ShowMoreLocation';
import ShowMoreText from '@alltrails/shared/components/ShowMoreText';
import { BREAKPOINT_MD_SIZE } from '@alltrails/denali/tokens';
import { useMediaQuery } from '@alltrails/core';
import classNames from 'classnames';
import PlusCta from '../PlusCta';
import styles from './Description.module.scss';
import useShouldRenderPlusCta from '@/hooks/useShouldRenderPlusCta';

type Props = {
  overview: string;
  isBot: boolean;
  pageType?: PageType;
  seoFeatureAATest?: boolean;
  hasSpacingExperiment?: boolean;
};

const Description = ({ overview, isBot, pageType, seoFeatureAATest, hasSpacingExperiment }: Props) => {
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINT_MD_SIZE}px)`);
  const experimentMaxLines = isMobile ? 2 : 3;
  const defaultMaxLines = isMobile ? 3 : 4;

  const renderPlusCta = useShouldRenderPlusCta();

  return (
    <>
      {isBot ? (
        <div className={styles.locationDescription}>{overview}</div>
      ) : (
        <ShowMoreText
          text={overview}
          className={classNames(styles.locationDescription, hasSpacingExperiment && styles.smallerLocationDescription)}
          maxLines={hasSpacingExperiment ? experimentMaxLines : defaultMaxLines}
          testIdPrefix="location-description"
          useShortText
          onClick={() => {
            if (pageType) logShowMoreClicked({ page_type: pageType, show_more_location: ShowMoreLocation.Overview });
          }}
        />
      )}
      {renderPlusCta && <PlusCta />}
      <div className={styles.divider} id={seoFeatureAATest ? 'seo-aa-test' : undefined} />
    </>
  );
};

export default Description;
